import React, { useState, createContext, useEffect } from 'react';

const initialState = {
  activeColour: '#000000',
  pageLoaderText: null,
  pageloaderColour: '#000000',
  reverseLoaderText: false,
};

export const StateContext = createContext();

const StateProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--loader',
      state.pageloaderColour,
    );
  }, [state.activeColour, state.pageloaderColour]);

  return (
    <StateContext.Provider value={{ state, setState }}>
      {children}
    </StateContext.Provider>
  );
};

const useSiteContext = () => {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error(`useSiteContext must be used within a SiteProvider`);
  }
  return context;
};

function useLoaderText() {
  const { setState } = useSiteContext();

  function switchLoaderText(value) {
    setState((prevState) => {
      return {
        ...prevState,
        pageLoaderText: value,
      };
    });
  }
  return switchLoaderText;
}

function useLoaderColour() {
  const { setState } = useSiteContext();

  function switchLoaderColour(value) {
    setState((prevState) => {
      return {
        ...prevState,
        pageloaderColour: value,
      };
    });
  }
  return switchLoaderColour;
}

function useReverseLoaderText() {
  const { setState } = useSiteContext();

  function switchReverseLoaderText(value) {
    setState((prevState) => {
      return {
        ...prevState,
        reverseLoaderText: value,
      };
    });
  }
  return switchReverseLoaderText;
}

export {
  StateProvider,
  useSiteContext,
  useLoaderText,
  useLoaderColour,
  useReverseLoaderText,
};
